var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-config')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"config",attrs:{"enctype":"multipart/form-data"}},[_c('b-form-group',[_c('label',{attrs:{"for":"input-1"}},[_vm._v("Config Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.form.config_name.$error,
              },attrs:{"id":"input-1","placeholder":"Enter Config Name"},model:{value:(_vm.form.config_name),callback:function ($$v) {_vm.$set(_vm.form, "config_name", $$v)},expression:"form.config_name"}}),(_vm.submitted && !_vm.$v.form.config_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Config Name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"position-relative"},[_c('label',{attrs:{"for":"input-2"}},[_vm._v("Value "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.form.value.$error,
              },attrs:{"id":"input-2","maxlength":250,"placeholder":"Enter Value"},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}),(_vm.submitted && !_vm.$v.form.value.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Value is required. ")]):_vm._e(),_c('div',{staticClass:"text-right"},[(_vm.form.value)?_c('p',{staticClass:"badge position-absolute",class:{
                  'badge-success': _vm.form.value.length !== 250,
                  'badge-danger': _vm.form.value.length === 250
                },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.value.length)+" out of 250 chars. ")]):_vm._e()])],1),_c('b-form-group',[_c('label',{attrs:{"for":"input-4"}},[_vm._v("Tell Us More About Config "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ckeditor',{class:{
                'is-invalid': _vm.submitted && _vm.$v.form.description.$error,
              },model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),(_vm.submitted && !_vm.$v.form.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description is required. ")]):_vm._e()],1),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-config')?_c('span',[_vm._v("Save Data")]):_c('span',[_vm._v("Update Data")])])],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }